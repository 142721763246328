.slider ul {
  transform: none !important;
}

.why-choose-bunches .contents {
  height: 98px;
}

.why-choose-bunches .content {
  padding: 0px !important;
}

.slider-item-infikey {
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider .btn {
  position: relative !important;
  top: 67px !important;
}
