:root {
  --colour-white: #fff;
  --colour-black: #000;
  --colour-lightest-grey: #f6f6f6;
  --colour-light-grey: #e4e4e4;
  --colour-grey: #ced0d2;
  --colour-medium-grey: #e7e8e8;
  --colour-darkish-grey: #8a979e;
  --colour-dark-grey: #596267;
  --colour-dark-grey-hover: #606a70;
  --colour-dark-grey-hover-text: #758188;
  --colour-darker-grey: #363d40;
  --colour-darkist-grey: #292b2c;
  --colour-brand-green: #afd7bb;
  --colour-brand-darkened-green: #7cbe90;
  --colour-brand-dark-green: #4f9e67;
  --colour-brand-green-hover: #b3d9be;
  --colour-brand-green-hover-text: #3e7c51;
  --colour-brand-light-blue: #e3f1f7;
  --colour-brand-blue: #94cbe0;
  --colour-brand-pink: #eecde4;
  --colour-brand-dark-pink: #d9bad0;
  --colour-brand-salmon-pink: #f6ddd8;
  --colour-brand-light-yellow: #ecedde;
  --colour-brand-peach: #f3c5ad;
  --colour-brand-light-peach: #fbeee7;
  --colour-brand-teal: #4d9699;
  --colour-brand-teal-two: #78a3ab;
  --colour-brand-teal-three: #82acb1;
  --colour-brand-teal-four: #d0e9e9;
  --colour-brand-teal-five: #ebf1f2;
  --colour-brand-cream: #fbf2ed;
  --colour-brand-cream-two: #f6dec7;
  --colour-brand-cream-three: #faeee5;
  --colour-brand-cream-four: #fef2db;
  --colour-brand-darker-cream: #f6eee8;
  --colour-brand-brown: #c89c88;
  --colour-brand-purple: #977eb8;
  --colour-brand-light-purple: #e2e0f5;
  --colour-warning: #f87070;
  --colour-warning-hover: #f98d8d;
  --colour-success: #c9f1d5;
  --colour-advisory: #fcf8e3;
  --colour-advisory-border: #faf2cc;
  --colour-advisory-text: #8a6d3b;
  --border-radius: 3px;
  --border-circle: 50px;
  --font: 18px/1.5 "BrandonGrotesque-Regular", open_sansregular, -apple-system,
    system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans,
    sans-serif, blinkmacsystemfont, "Segoe UI", roboto, "Helvetica Neue", arial,
    sans-serif;
  --font-light: 18px/1.5 "BrandonGrotesque-Light", open_sansregular,
    -apple-system, system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell,
    Noto Sans, sans-serif, blinkmacsystemfont, "Segoe UI", roboto,
    "Helvetica Neue", arial, sans-serif;
  --font-medium: 18px/1.5 "BrandonGrotesque-Medium", open_sansregular,
    -apple-system, system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell,
    Noto Sans, sans-serif, blinkmacsystemfont, "Segoe UI", roboto,
    "Helvetica Neue", arial, sans-serif;
  --font-bold: 18px/1.5 "BrandonGrotesque-Bold", open_sansregular, -apple-system,
    system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans,
    sans-serif, blinkmacsystemfont, "Segoe UI", roboto, "Helvetica Neue", arial,
    sans-serif;
  --transition: 150ms ease-in-out;
  --underline-offset: 0.2em;
}

.GrzYCAN15wR8r2PacL8L {
  background-color: #ffc0cb;
  margin-bottom: 1em;
  padding: 1.5em 0 1.7em;
}

@media (min-width: 768px) {
  .GrzYCAN15wR8r2PacL8L {
    padding: 2em 0;
  }
}

.GrzYCAN15wR8r2PacL8L .W69v6egqRnctKQEMOtd9 {
  grid-gap: 1em;
  display: grid;
  gap: 1em;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(6, 1fr);
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  width: 540px;
}

@media (min-width: 321px) {
  .GrzYCAN15wR8r2PacL8L .W69v6egqRnctKQEMOtd9 {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    width: 540px;
  }
}

@media (min-width: 576px) {
  .GrzYCAN15wR8r2PacL8L .W69v6egqRnctKQEMOtd9 {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    width: 540px;
  }
}

@media (min-width: 768px) {
  .GrzYCAN15wR8r2PacL8L .W69v6egqRnctKQEMOtd9 {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    width: 720px;
  }
}

@media (min-width: 992px) {
  .GrzYCAN15wR8r2PacL8L .W69v6egqRnctKQEMOtd9 {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    width: 960px;
  }
}

@media (min-width: 1200px) {
  .GrzYCAN15wR8r2PacL8L .W69v6egqRnctKQEMOtd9 {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    width: 1140px;
  }
}

@media (min-width: 768px) {
  .GrzYCAN15wR8r2PacL8L .W69v6egqRnctKQEMOtd9 {
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(1, 1fr);
  }
}

@media (min-width: 992px) {
  .GrzYCAN15wR8r2PacL8L .W69v6egqRnctKQEMOtd9 {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(1, 1fr);
    row-gap: 0;
  }
}

@media (min-width: 1200px) {
  .GrzYCAN15wR8r2PacL8L .W69v6egqRnctKQEMOtd9 {
    column-gap: 2.5em;
  }
}

.GrzYCAN15wR8r2PacL8L .W69v6egqRnctKQEMOtd9 h2 {
  align-items: center;
  display: flex;
  font-size: 1.2em;
  justify-content: center;
  text-align: center;
}

@media (min-width: 321px) {
  .GrzYCAN15wR8r2PacL8L .W69v6egqRnctKQEMOtd9 h2 {
    font-size: 1.3em;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .GrzYCAN15wR8r2PacL8L .W69v6egqRnctKQEMOtd9 h2 {
    margin-bottom: 0.5em;
  }
}

@media (min-width: 768px) {
  .GrzYCAN15wR8r2PacL8L .W69v6egqRnctKQEMOtd9 h2 {
    grid-area: 1/1/2/6;
  }
}

@media (min-width: 992px) {
  .GrzYCAN15wR8r2PacL8L .W69v6egqRnctKQEMOtd9 h2 {
    font-size: 1.5em;
    grid-area: 1/1/3/3;
    justify-content: flex-start;
    text-align: left;
  }
}

@media (min-width: 1200px) {
  .GrzYCAN15wR8r2PacL8L .W69v6egqRnctKQEMOtd9 h2 {
    width: 9em;
  }
}

.GrzYCAN15wR8r2PacL8L .W69v6egqRnctKQEMOtd9 p {
  color: #363d40;
  color: var(--colour-darker-grey);
  width: 12em;
}

@media (max-width: 320px) {
  .GrzYCAN15wR8r2PacL8L .W69v6egqRnctKQEMOtd9 p {
    font-size: 0.9em;
  }
}

@media (min-width: 768px) {
  .GrzYCAN15wR8r2PacL8L .W69v6egqRnctKQEMOtd9 p {
    width: 7em;
  }

  .GrzYCAN15wR8r2PacL8L .W69v6egqRnctKQEMOtd9 a:nth-child(2) {
    grid-area: 2/1/3/2;
  }
}

@media (min-width: 992px) {
  .GrzYCAN15wR8r2PacL8L .W69v6egqRnctKQEMOtd9 a:nth-child(2) {
    grid-area: 1/3/3/4;
  }
}

@media (min-width: 768px) {
  .GrzYCAN15wR8r2PacL8L .W69v6egqRnctKQEMOtd9 a:nth-child(3) {
    grid-area: 2/2/3/3;
  }
}

@media (min-width: 992px) {
  .GrzYCAN15wR8r2PacL8L .W69v6egqRnctKQEMOtd9 a:nth-child(3) {
    grid-area: 1/4/3/5;
  }
}

@media (min-width: 768px) {
  .GrzYCAN15wR8r2PacL8L .W69v6egqRnctKQEMOtd9 a:nth-child(4) {
    grid-area: 2/3/3/4;
  }
}

@media (min-width: 992px) {
  .GrzYCAN15wR8r2PacL8L .W69v6egqRnctKQEMOtd9 a:nth-child(4) {
    grid-area: 1/5/3/6;
  }
}

@media (min-width: 768px) {
  .GrzYCAN15wR8r2PacL8L .W69v6egqRnctKQEMOtd9 a:nth-child(5) {
    grid-area: 2/4/3/5;
  }
}

@media (min-width: 992px) {
  .GrzYCAN15wR8r2PacL8L .W69v6egqRnctKQEMOtd9 a:nth-child(5) {
    grid-area: 1/6/3/7;
  }
}

@media (min-width: 768px) {
  .GrzYCAN15wR8r2PacL8L .W69v6egqRnctKQEMOtd9 a:nth-child(5) p {
    width: 5em;
  }
}

@media (min-width: 992px) {
  .GrzYCAN15wR8r2PacL8L .W69v6egqRnctKQEMOtd9 a:nth-child(5) p {
    width: 6em;
  }
}

@media (min-width: 768px) {
  .GrzYCAN15wR8r2PacL8L .W69v6egqRnctKQEMOtd9 a:nth-child(6) {
    grid-area: 2/5/3/6;
  }
}

@media (min-width: 992px) {
  .GrzYCAN15wR8r2PacL8L .W69v6egqRnctKQEMOtd9 a:nth-child(6) {
    grid-area: 1/7/3/8;
  }
}

.GrzYCAN15wR8r2PacL8L .W69v6egqRnctKQEMOtd9 .X93EgoVWW3eQsYa9Rrpw {
  align-items: center;
  display: flex;
  justify-content: center;
}

@media (min-width: 768px) {
  .GrzYCAN15wR8r2PacL8L .W69v6egqRnctKQEMOtd9 .X93EgoVWW3eQsYa9Rrpw {
    flex-direction: column;
    text-align: center;
    width: 7em;
  }
}

.GrzYCAN15wR8r2PacL8L .W69v6egqRnctKQEMOtd9 .X93EgoVWW3eQsYa9Rrpw img {
  margin-right: 1em;
  width: 2.3em;
}

@media (min-width: 768px) {
  .GrzYCAN15wR8r2PacL8L .W69v6egqRnctKQEMOtd9 .X93EgoVWW3eQsYa9Rrpw img {
    height: 2.3em;
    margin-bottom: 0.8em;
    margin-right: 0;
    width: auto;
  }
}

:root {
  --colour-white: #fff;
  --colour-black: #000;
  --colour-lightest-grey: #f6f6f6;
  --colour-light-grey: #e4e4e4;
  --colour-grey: #ced0d2;
  --colour-medium-grey: #e7e8e8;
  --colour-darkish-grey: #8a979e;
  --colour-dark-grey: #596267;
  --colour-dark-grey-hover: #606a70;
  --colour-dark-grey-hover-text: #758188;
  --colour-darker-grey: #363d40;
  --colour-darkist-grey: #292b2c;
  --colour-brand-green: #afd7bb;
  --colour-brand-darkened-green: #7cbe90;
  --colour-brand-dark-green: #4f9e67;
  --colour-brand-green-hover: #b3d9be;
  --colour-brand-green-hover-text: #3e7c51;
  --colour-brand-light-blue: #e3f1f7;
  --colour-brand-blue: #94cbe0;
  --colour-brand-pink: #eecde4;
  --colour-brand-dark-pink: #d9bad0;
  --colour-brand-salmon-pink: #f6ddd8;
  --colour-brand-light-yellow: #ecedde;
  --colour-brand-peach: #f3c5ad;
  --colour-brand-light-peach: #fbeee7;
  --colour-brand-teal: #4d9699;
  --colour-brand-teal-two: #78a3ab;
  --colour-brand-teal-three: #82acb1;
  --colour-brand-teal-four: #d0e9e9;
  --colour-brand-teal-five: #ebf1f2;
  --colour-brand-cream: #fbf2ed;
  --colour-brand-cream-two: #f6dec7;
  --colour-brand-cream-three: #faeee5;
  --colour-brand-cream-four: #fef2db;
  --colour-brand-darker-cream: #f6eee8;
  --colour-brand-brown: #c89c88;
  --colour-brand-purple: #977eb8;
  --colour-brand-light-purple: #e2e0f5;
  --colour-warning: #f87070;
  --colour-warning-hover: #f98d8d;
  --colour-success: #c9f1d5;
  --colour-advisory: #fcf8e3;
  --colour-advisory-border: #faf2cc;
  --colour-advisory-text: #8a6d3b;
  --border-radius: 3px;
  --border-circle: 50px;
  --font: 18px/1.5 "BrandonGrotesque-Regular", open_sansregular, -apple-system,
    system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans,
    sans-serif, blinkmacsystemfont, "Segoe UI", roboto, "Helvetica Neue", arial,
    sans-serif;
  --font-light: 18px/1.5 "BrandonGrotesque-Light", open_sansregular,
    -apple-system, system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell,
    Noto Sans, sans-serif, blinkmacsystemfont, "Segoe UI", roboto,
    "Helvetica Neue", arial, sans-serif;
  --font-medium: 18px/1.5 "BrandonGrotesque-Medium", open_sansregular,
    -apple-system, system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell,
    Noto Sans, sans-serif, blinkmacsystemfont, "Segoe UI", roboto,
    "Helvetica Neue", arial, sans-serif;
  --font-bold: 18px/1.5 "BrandonGrotesque-Bold", open_sansregular, -apple-system,
    system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans,
    sans-serif, blinkmacsystemfont, "Segoe UI", roboto, "Helvetica Neue", arial,
    sans-serif;
  --transition: 150ms ease-in-out;
  --underline-offset: 0.2em;
}

.gsFC_6Q_RL8IjB0u3IzQ {
  margin-bottom: 1.5em;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  width: 540px;
}

@media (min-width: 321px) {
  .gsFC_6Q_RL8IjB0u3IzQ {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    width: 540px;
  }
}

@media (min-width: 576px) {
  .gsFC_6Q_RL8IjB0u3IzQ {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    width: 540px;
  }
}

@media (min-width: 768px) {
  .gsFC_6Q_RL8IjB0u3IzQ {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    width: 720px;
  }
}

@media (min-width: 992px) {
  .gsFC_6Q_RL8IjB0u3IzQ {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    width: 960px;
  }
}

@media (min-width: 1200px) {
  .gsFC_6Q_RL8IjB0u3IzQ {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    width: 1140px;
  }
}

.gsFC_6Q_RL8IjB0u3IzQ .wE_PvUvh56VOaNOmELK6 {
  background-color: #ffc0cb !important;
  display: flex;
  flex-direction: column;
}

@media (min-width: 992px) {
  .gsFC_6Q_RL8IjB0u3IzQ .wE_PvUvh56VOaNOmELK6 {
    flex-direction: row;
  }
}

.gsFC_6Q_RL8IjB0u3IzQ .wE_PvUvh56VOaNOmELK6 img {
  -o-object-fit: cover;
  object-fit: cover;
}

@media (min-width: 768px) and (max-width: 991px) {
  .gsFC_6Q_RL8IjB0u3IzQ .wE_PvUvh56VOaNOmELK6 img {
    max-height: 18em;
    -o-object-position: top;
    object-position: top;
  }
}

@media (min-width: 992px) {
  .gsFC_6Q_RL8IjB0u3IzQ .wE_PvUvh56VOaNOmELK6 img {
    flex: 1;
    width: 50%;
  }
}

.gsFC_6Q_RL8IjB0u3IzQ .wE_PvUvh56VOaNOmELK6 .LwrVKt9HAl868tHDEPD2 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 1em;
  padding: 1em;
  text-align: center;
}

@media (min-width: 576px) {
  .gsFC_6Q_RL8IjB0u3IzQ .wE_PvUvh56VOaNOmELK6 .LwrVKt9HAl868tHDEPD2 {
    padding: 1.5em;
  }
}

@media (min-width: 768px) {
  .gsFC_6Q_RL8IjB0u3IzQ .wE_PvUvh56VOaNOmELK6 .LwrVKt9HAl868tHDEPD2 {
    padding: 2em 2.5em;
  }
}

.gsFC_6Q_RL8IjB0u3IzQ .wE_PvUvh56VOaNOmELK6 .LwrVKt9HAl868tHDEPD2 h2 {
  text-wrap: balance;
  font-size: 1.3em;
}

@media (min-width: 768px) {
  .gsFC_6Q_RL8IjB0u3IzQ .wE_PvUvh56VOaNOmELK6 .LwrVKt9HAl868tHDEPD2 h2 {
    font-size: 1.6em;
  }

  .gsFC_6Q_RL8IjB0u3IzQ .wE_PvUvh56VOaNOmELK6 .LwrVKt9HAl868tHDEPD2 p {
    text-wrap: balance;
  }
}
