@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Overlock:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&display=swap");

body {
  font-family: "Overlock", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Overlock", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
span {
  font-family: "Overlock", sans-serif;
}

input,
button,
option,
textarea {
  font-family: "Overlock", sans-serif;
}
