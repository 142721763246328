.custom-date-picker {
  font-family: "Montserrat", sans-serif; /* Custom font */
  color: #2c3e50; /* Custom color */
  width: 100%; /* Make the container 100% width */
}

.custom-date-picker .react-datepicker {
  width: 100%; /* Ensure the datepicker takes full width */
}

.custom-date-picker .react-datepicker__header {
  background-color: #ecf0f1; /* Custom header background */
  border-bottom: 1px solid #bdc3c7; /* Header border color */
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: white; /* Custom selected day background */
}

.custom-date-picker .react-datepicker__day--selected {
  background-color: #94cbe0; /* Custom selected day background */
  color: white; /* Custom selected day text color */
}

.custom-date-picker .react-datepicker__day:hover {
  background-color: #95a5a6; /* Custom hover effect */
  color: #ffffff;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 12%;
}

.react-datepicker__month-container {
  width: 100%;
}

.unavailable-date {
  color: #ccc !important;
  pointer-events: none; /* Ensure the dates cannot be clicked */
}

.react-datepicker__month-container {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.grayed-out-date {
  color: #ccc !important;
  pointer-events: none; /* Prevent clicking on grayed-out dates */
}

.react-datepicker {
  border: none;
}
