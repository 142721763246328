/* Loader.css */

@keyframes dot-keyframes {
  0% {
    opacity: 0.4;
    transform: scale(1, 1);
  }

  50% {
    opacity: 1;
    transform: scale(1.2, 1.2);
  }

  100% {
    opacity: 0.4;
    transform: scale(1, 1);
  }
}

.loading-dots {
  width: 100%;
}

.loading-dots--dot {
  margin: 0 1px;
  animation: dot-keyframes 1.5s infinite ease-in-out;
  background-color: #004b44;
  border-radius: 10px;
  display: inline-block;
  height: 10px;
  width: 10px;
}

.loading-dots--dot:nth-child(2) {
  animation-delay: 0.5s;
}

.loading-dots--dot:nth-child(3) {
  animation-delay: 1s;
}

.loading-dots--dot.white {
  background-color: #fff;
}

.uk-accordion-title::after {
  display: none;
}

.display--inline {
  display: inline;
}

.align--center {
  text-align: center;
}
.loading {
  height: 50px; /* Adjust height as needed */
  width: 50px; /* Adjust width as needed */
  border: 6px solid #ccc;
  border-right-color: #888;
  border-radius: 50%; /* Use border-radius to create a circle */
  animation: rotate 1s infinite linear; /* Use standard animation property */
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
