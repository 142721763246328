.Bdw6gjfEGWMsf_bkFBjv {
  height: 100vh;
  overflow: auto;
}

@media (min-width: 992px) {
  .Bdw6gjfEGWMsf_bkFBjv {
    height: 70vh;
    overflow: hidden;
  }
}

.Bdw6gjfEGWMsf_bkFBjv .WGacRckKKIUoVJZNnFeq {
  background-color: #fff;
  background-color: var(--colour-white);
  box-shadow: 0 3px 5px 0 #ced0d2;
  box-shadow: 0 3px 5px 0 var(--colour-grey);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
}

.Bdw6gjfEGWMsf_bkFBjv .WGacRckKKIUoVJZNnFeq h1 {
  font-size: 1.4em;
  padding: 0.5em 0;
  text-align: center;
}

@media (min-width: 321px) {
  .Bdw6gjfEGWMsf_bkFBjv .WGacRckKKIUoVJZNnFeq h1 {
    font-size: 1.8em;
  }
}

.Bdw6gjfEGWMsf_bkFBjv .w4nQfR5xKnljWt0qxKLg {
  display: flex;
  flex-direction: column;
  height: calc(100% - 80px);
  justify-content: space-between;
  overflow: auto;
}

@media (min-width: 992px) {
  .Bdw6gjfEGWMsf_bkFBjv .w4nQfR5xKnljWt0qxKLg.Pn0XaEff_rcABAfUXSDn {
    margin: 1em auto;
  }
}

.Bdw6gjfEGWMsf_bkFBjv .w4nQfR5xKnljWt0qxKLg .Sdbyk2VEHS2zgN7nRXmn {
  margin: 5em 0 1em;
  text-align: center;
}

@media (min-width: 992px) {
  .Bdw6gjfEGWMsf_bkFBjv .w4nQfR5xKnljWt0qxKLg .Sdbyk2VEHS2zgN7nRXmn {
    display: none;
  }
}

.Bdw6gjfEGWMsf_bkFBjv .w4nQfR5xKnljWt0qxKLg .options {
  box-shadow: 0 5px 8px rgba(0, 32, 77, 0.15), 0 5px 8px rgba(0, 32, 77, 0.15);
  display: block;
  position: absolute;
  top: 62px;
  z-index: 9;
}

@media (min-width: 321px) {
  .Bdw6gjfEGWMsf_bkFBjv .w4nQfR5xKnljWt0qxKLg .options {
    top: 79px;
  }
}

@media (min-width: 992px) {
  .Bdw6gjfEGWMsf_bkFBjv .w4nQfR5xKnljWt0qxKLg .options {
    box-shadow: none;
    margin: 1em auto;
    position: relative;
    top: auto;
    width: 60%;
  }
}

@media (max-width: 991px) {
  .Bdw6gjfEGWMsf_bkFBjv .w4nQfR5xKnljWt0qxKLg .options .options-tabs {
    border-left: 0;
    border-radius: 0;
    border-right: 0;
  }
}

.Bdw6gjfEGWMsf_bkFBjv .w4nQfR5xKnljWt0qxKLg .options .selected-option {
  border-radius: 0;
}

.Bdw6gjfEGWMsf_bkFBjv .w4nQfR5xKnljWt0qxKLg .RGSNbTxX7yhVztdxhyBE {
  display: flex;
  flex-direction: column;
  gap: 1em;
  justify-content: center;
  margin: 1em 0;
  padding: 1em;
}

@media (min-width: 768px) {
  .Bdw6gjfEGWMsf_bkFBjv .w4nQfR5xKnljWt0qxKLg .RGSNbTxX7yhVztdxhyBE {
    flex-direction: row-reverse;
  }
}

._3LpYaOIfjieYwbco8s_ .OltPgYCmlYCMv4b1Sm3U {
  align-items: center;
  display: flex;
  flex-direction: column;
}

._3LpYaOIfjieYwbco8s_ .QzJRhxyOEurUX1pBKLqz {
  font-size: 1.6em;
}

._3LpYaOIfjieYwbco8s_ .D5YYXT3oLM8xMkgQKuN1 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin: 1em 0;
}

@media (min-width: 576px) {
  ._3LpYaOIfjieYwbco8s_ .D5YYXT3oLM8xMkgQKuN1.CzCY_0Yh2CemLc5Oe_5A {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 992px) {
  ._3LpYaOIfjieYwbco8s_ .D5YYXT3oLM8xMkgQKuN1.CzCY_0Yh2CemLc5Oe_5A {
    display: flex;
  }
}

@media (min-width: 768px) {
  ._3LpYaOIfjieYwbco8s_ .D5YYXT3oLM8xMkgQKuN1 {
    flex-direction: row;
  }
}

._3LpYaOIfjieYwbco8s_ .e3hmssPfNwwVhUi5NO0e {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

@media (min-width: 992px) {
  ._3LpYaOIfjieYwbco8s_ .e3hmssPfNwwVhUi5NO0e {
    flex-direction: row;
  }
}

._3LpYaOIfjieYwbco8s_ .e3hmssPfNwwVhUi5NO0e .sDdk4oezt7sVomqHXh1a {
  border: 1px solid #e4e4e4;
  border: 1px solid var(--colour-light-grey);
  flex: 1;
  padding: 1em;
}

._3LpYaOIfjieYwbco8s_
  .e3hmssPfNwwVhUi5NO0e
  .sDdk4oezt7sVomqHXh1a
  .fRUoQupckgDoODJYB25s {
  display: flex;
  justify-content: center;
}

._3LpYaOIfjieYwbco8s_
  .e3hmssPfNwwVhUi5NO0e
  .sDdk4oezt7sVomqHXh1a
  .fRUoQupckgDoODJYB25s
  img {
  margin: 0.5em;
  max-width: 90%;
}

@media (min-width: 321px) {
  ._3LpYaOIfjieYwbco8s_
    .e3hmssPfNwwVhUi5NO0e
    .sDdk4oezt7sVomqHXh1a
    .fRUoQupckgDoODJYB25s
    img {
    max-width: 70%;
  }
}

@media (min-width: 576px) {
  ._3LpYaOIfjieYwbco8s_
    .e3hmssPfNwwVhUi5NO0e
    .sDdk4oezt7sVomqHXh1a
    .fRUoQupckgDoODJYB25s
    img {
    max-width: 80%;
  }
}

@media (min-width: 768px) {
  ._3LpYaOIfjieYwbco8s_
    .e3hmssPfNwwVhUi5NO0e
    .sDdk4oezt7sVomqHXh1a
    .fRUoQupckgDoODJYB25s
    img {
    max-width: 50%;
  }
}

._3LpYaOIfjieYwbco8s_
  .e3hmssPfNwwVhUi5NO0e
  .sDdk4oezt7sVomqHXh1a
  .xO3gL7da3KtpYv2RsKyp {
  display: block;
  font-size: 1.2em;
  margin-bottom: 0.5em;
}

._3LpYaOIfjieYwbco8s_
  .e3hmssPfNwwVhUi5NO0e
  .sDdk4oezt7sVomqHXh1a
  .xO3gL7da3KtpYv2RsKyp
  .gfbDrlr_krgmkFLMx9_g {
  font-size: 0.8em;
  font-style: italic;
}

._3LpYaOIfjieYwbco8s_
  .e3hmssPfNwwVhUi5NO0e
  .sDdk4oezt7sVomqHXh1a
  .LC1tC2T7q5o5SWSpFR0C {
  font-size: 1rem;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

._3LpYaOIfjieYwbco8s_
  .e3hmssPfNwwVhUi5NO0e
  .sDdk4oezt7sVomqHXh1a
  .LC1tC2T7q5o5SWSpFR0C
  li {
  font-size: 1.2em;
  margin: 0.5em 0;
}

._3LpYaOIfjieYwbco8s_
  .e3hmssPfNwwVhUi5NO0e
  .sDdk4oezt7sVomqHXh1a
  .LC1tC2T7q5o5SWSpFR0C
  .j42wJ0dNRJwgEP6Hi38Q {
  display: flex;
  justify-content: space-between;
}

._3LpYaOIfjieYwbco8s_
  .e3hmssPfNwwVhUi5NO0e
  .sDdk4oezt7sVomqHXh1a
  .ypVqO4EH3a5IUtcfBcpv {
  list-style-type: none;
}

._3LpYaOIfjieYwbco8s_
  .e3hmssPfNwwVhUi5NO0e
  .sDdk4oezt7sVomqHXh1a
  .ypVqO4EH3a5IUtcfBcpv
  li {
  margin: 0.3em 0;
}

._3LpYaOIfjieYwbco8s_ .personalised-container {
  margin-top: 1em;
}

._3LpYaOIfjieYwbco8s_
  .personalised-container
  .card-message-inside
  .inside-right {
  margin: 0 auto !important;
}

@media (min-width: 576px) {
  ._3LpYaOIfjieYwbco8s_
    .personalised-container
    .card-message-inside
    .inside-right {
    margin-left: 0;
  }
}

@media (min-width: 992px) {
  ._3LpYaOIfjieYwbco8s_
    .personalised-container
    .card-message-inside
    .inside-right.standard {
    font-size: 12.5px;
    height: 66mm;
    width: 88mm;
  }
}

@media (min-width: 768px) {
  ._3LpYaOIfjieYwbco8s_
    .personalised-container
    .card-message-inside
    .inside-right.a5 {
    font-size: 8.69px;
    height: 113.98mm;
    width: 80.33mm;
  }

  ._3LpYaOIfjieYwbco8s_
    .personalised-container
    .card-message-inside
    .inside-right.a6 {
    font-size: 13.2px;
    height: 118.9mm;
    width: 86mm;
  }
}

._3LpYaOIfjieYwbco8s_
  .personalised-container
  .card-message-inside
  .inside-right
  span {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

._3LpYaOIfjieYwbco8s_ .eoeEIIxbgX0QwouHdCuQ {
  margin: 0.5em 0;
}

._3LpYaOIfjieYwbco8s_ .eoeEIIxbgX0QwouHdCuQ .Xma8KDSm9rwGXloEF5YO {
  word-break: break-word;
}

._3LpYaOIfjieYwbco8s_
  .eoeEIIxbgX0QwouHdCuQ
  .Xma8KDSm9rwGXloEF5YO.k6PavdPrFTM10A1_9zfO {
  font-size: 11.2px;
  height: 59.22mm;
  max-width: 84mm;
}

._3LpYaOIfjieYwbco8s_
  .eoeEIIxbgX0QwouHdCuQ
  .Xma8KDSm9rwGXloEF5YO.k6PavdPrFTM10A1_9zfO
  .V4Ars12DdKN_jxe43crC {
  font-size: 11.2px;
}

@media (min-width: 576px) {
  ._3LpYaOIfjieYwbco8s_
    .eoeEIIxbgX0QwouHdCuQ
    .Xma8KDSm9rwGXloEF5YO.k6PavdPrFTM10A1_9zfO {
    font-size: 12.8px;
    height: 67.68mm;
    width: 96mm;
  }

  ._3LpYaOIfjieYwbco8s_
    .eoeEIIxbgX0QwouHdCuQ
    .Xma8KDSm9rwGXloEF5YO.k6PavdPrFTM10A1_9zfO
    .V4Ars12DdKN_jxe43crC {
    font-size: 12.8px;
  }
}

@media (min-width: 1200px) {
  ._3LpYaOIfjieYwbco8s_
    .eoeEIIxbgX0QwouHdCuQ
    .Xma8KDSm9rwGXloEF5YO.k6PavdPrFTM10A1_9zfO {
    font-size: 14.14px;
    height: 76.14mm;
    width: 108mm;
  }

  ._3LpYaOIfjieYwbco8s_
    .eoeEIIxbgX0QwouHdCuQ
    .Xma8KDSm9rwGXloEF5YO.k6PavdPrFTM10A1_9zfO
    .V4Ars12DdKN_jxe43crC {
    font-size: 14.14px;
  }
}

._3LpYaOIfjieYwbco8s_
  .eoeEIIxbgX0QwouHdCuQ
  .Xma8KDSm9rwGXloEF5YO.SdayVYkg_K41X1KmFPdM {
  font-size: 13.5px;
  height: 37.5mm;
  max-width: 90mm;
}

._3LpYaOIfjieYwbco8s_
  .eoeEIIxbgX0QwouHdCuQ
  .Xma8KDSm9rwGXloEF5YO.SdayVYkg_K41X1KmFPdM
  .V4Ars12DdKN_jxe43crC {
  font-size: 13.5px;
}

@media (min-width: 576px) {
  ._3LpYaOIfjieYwbco8s_
    .eoeEIIxbgX0QwouHdCuQ
    .Xma8KDSm9rwGXloEF5YO.SdayVYkg_K41X1KmFPdM {
    font-size: 14.4px;
    height: 40mm;
    width: 96mm;
  }

  ._3LpYaOIfjieYwbco8s_
    .eoeEIIxbgX0QwouHdCuQ
    .Xma8KDSm9rwGXloEF5YO.SdayVYkg_K41X1KmFPdM
    .V4Ars12DdKN_jxe43crC {
    font-size: 14.4px;
  }
}

@media (min-width: 1200px) {
  ._3LpYaOIfjieYwbco8s_
    .eoeEIIxbgX0QwouHdCuQ
    .Xma8KDSm9rwGXloEF5YO.SdayVYkg_K41X1KmFPdM {
    font-size: 16.2px;
    height: 45mm;
    width: 108mm;
  }

  ._3LpYaOIfjieYwbco8s_
    .eoeEIIxbgX0QwouHdCuQ
    .Xma8KDSm9rwGXloEF5YO.SdayVYkg_K41X1KmFPdM
    .V4Ars12DdKN_jxe43crC {
    font-size: 16.2px;
  }
}

._3LpYaOIfjieYwbco8s_
  .eoeEIIxbgX0QwouHdCuQ
  .Xma8KDSm9rwGXloEF5YO
  .V4Ars12DdKN_jxe43crC {
  word-wrap: break-word;
  align-items: center;
  color: #000;
  color: var(--colour-black);
  display: flex;
  flex-direction: column;
  justify-content: center;
  white-space: pre-wrap;
}

._3LpYaOIfjieYwbco8s_ .RXeEklUTi5xSpbeJUlVq {
  margin-left: 1.75em;
  margin-top: 0.3em;
  white-space: pre-wrap;
  width: 100%;
  word-break: break-word;
}

._3LpYaOIfjieYwbco8s_ .czhXuGjaWm3UPGyi70WG {
  margin: 2em 0 1em;
  text-align: center;
}

._3LpYaOIfjieYwbco8s_ .czhXuGjaWm3UPGyi70WG a {
  display: inline-block;
}

:root {
  --colour-white: #fff;
  --colour-black: #000;
  --colour-lightest-grey: #f6f6f6;
  --colour-light-grey: #e4e4e4;
  --colour-grey: #ced0d2;
  --colour-medium-grey: #e7e8e8;
  --colour-darkish-grey: #8a979e;
  --colour-dark-grey: #596267;
  --colour-dark-grey-hover: #606a70;
  --colour-dark-grey-hover-text: #758188;
  --colour-darker-grey: #363d40;
  --colour-darkist-grey: #292b2c;
  --colour-brand-green: #afd7bb;
  --colour-brand-darkened-green: #7cbe90;
  --colour-brand-dark-green: #4f9e67;
  --colour-brand-green-hover: #b3d9be;
  --colour-brand-green-hover-text: #3e7c51;
  --colour-brand-light-blue: #e3f1f7;
  --colour-brand-blue: #94cbe0;
  --colour-brand-pink: #eecde4;
  --colour-brand-dark-pink: #d9bad0;
  --colour-brand-salmon-pink: #f6ddd8;
  --colour-brand-light-yellow: #ecedde;
  --colour-brand-peach: #f3c5ad;
  --colour-brand-light-peach: #fbeee7;
  --colour-brand-teal: #4d9699;
  --colour-brand-teal-two: #78a3ab;
  --colour-brand-teal-three: #82acb1;
  --colour-brand-teal-four: #d0e9e9;
  --colour-brand-teal-five: #ebf1f2;
  --colour-brand-cream: #fbf2ed;
  --colour-brand-cream-two: #f6dec7;
  --colour-brand-cream-three: #faeee5;
  --colour-brand-cream-four: #fef2db;
  --colour-brand-darker-cream: #f6eee8;
  --colour-brand-brown: #c89c88;
  --colour-brand-purple: #977eb8;
  --colour-brand-light-purple: #e2e0f5;
  --colour-warning: #f87070;
  --colour-warning-hover: #f98d8d;
  --colour-success: #c9f1d5;
  --colour-advisory: #fcf8e3;
  --colour-advisory-border: #faf2cc;
  --colour-advisory-text: #8a6d3b;
  --border-radius: 3px;
  --border-circle: 50px;
  --font: 18px/1.5 "BrandonGrotesque-Regular", open_sansregular, -apple-system,
    system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans,
    sans-serif, blinkmacsystemfont, "Segoe UI", roboto, "Helvetica Neue", arial,
    sans-serif;
  --font-light: 18px/1.5 "BrandonGrotesque-Light", open_sansregular,
    -apple-system, system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell,
    Noto Sans, sans-serif, blinkmacsystemfont, "Segoe UI", roboto,
    "Helvetica Neue", arial, sans-serif;
  --font-medium: 18px/1.5 "BrandonGrotesque-Medium", open_sansregular,
    -apple-system, system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell,
    Noto Sans, sans-serif, blinkmacsystemfont, "Segoe UI", roboto,
    "Helvetica Neue", arial, sans-serif;
  --font-bold: 18px/1.5 "BrandonGrotesque-Bold", open_sansregular, -apple-system,
    system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans,
    sans-serif, blinkmacsystemfont, "Segoe UI", roboto, "Helvetica Neue", arial,
    sans-serif;
  --transition: 150ms ease-in-out;
  --underline-offset: 0.2em;
}

.vaF02FJcvbQm8612Ejv4 .Tg3Kn7LFXB_J1vgyc2Ml {
  display: flex;
  flex-direction: column;
  margin-top: 2em;
  text-align: center;
}

.vaF02FJcvbQm8612Ejv4 .Tg3Kn7LFXB_J1vgyc2Ml a {
  -webkit-text-decoration: underline !important;
  text-decoration: underline !important;
  text-underline-offset: 0.2em;
  text-underline-offset: var(--underline-offset);
}

.vaF02FJcvbQm8612Ejv4 .Tg3Kn7LFXB_J1vgyc2Ml .pcyUpWXdmQo7h9iZkZNr {
  background-color: #f6f6f6;
  background-color: var(--colour-lightest-grey);
  border-radius: 3px;
  border-radius: var(--border-radius);
  margin: 0 -15px 1em;
  padding: 1em;
}

@media (min-width: 768px) {
  .vaF02FJcvbQm8612Ejv4 .Tg3Kn7LFXB_J1vgyc2Ml .pcyUpWXdmQo7h9iZkZNr {
    margin: 0 0 1em;
  }
}

.vaF02FJcvbQm8612Ejv4
  .Tg3Kn7LFXB_J1vgyc2Ml
  .pcyUpWXdmQo7h9iZkZNr.yw74dQpw0INYEnIvogPg {
  padding: 2em 1em 1em;
}

.vaF02FJcvbQm8612Ejv4
  .Tg3Kn7LFXB_J1vgyc2Ml
  .pcyUpWXdmQo7h9iZkZNr.yw74dQpw0INYEnIvogPg
  .Es89ngdXuxP9JNIE1WA5 {
  margin: 0.2em;
}

@media (min-width: 992px) {
  .vaF02FJcvbQm8612Ejv4
    .Tg3Kn7LFXB_J1vgyc2Ml
    .pcyUpWXdmQo7h9iZkZNr.OicELNhsbxVX9y7QdeQQ,
  .vaF02FJcvbQm8612Ejv4
    .Tg3Kn7LFXB_J1vgyc2Ml
    .pcyUpWXdmQo7h9iZkZNr.yw74dQpw0INYEnIvogPg {
    padding: 2em 12em 1em;
  }
}

.vaF02FJcvbQm8612Ejv4
  .Tg3Kn7LFXB_J1vgyc2Ml
  .pcyUpWXdmQo7h9iZkZNr
  .kEIud88rzfDjQPSiFYno {
  align-items: center;
  display: flex;
  justify-content: center;
}

.vaF02FJcvbQm8612Ejv4
  .Tg3Kn7LFXB_J1vgyc2Ml
  .pcyUpWXdmQo7h9iZkZNr.OicELNhsbxVX9y7QdeQQ
  .kEIud88rzfDjQPSiFYno {
  margin: 0.6em;
}

.vaF02FJcvbQm8612Ejv4 .Tg3Kn7LFXB_J1vgyc2Ml .GvOPoLFZKfi6CIxhIs0a {
  margin-bottom: 1em;
}

.vaF02FJcvbQm8612Ejv4 .Tg3Kn7LFXB_J1vgyc2Ml .FbKbhUCtMDRwuI6ipmZp {
  align-items: center;
  display: flex;
  justify-content: center;
}

.vaF02FJcvbQm8612Ejv4 .Tg3Kn7LFXB_J1vgyc2Ml .FbKbhUCtMDRwuI6ipmZp label {
  cursor: pointer;
  margin: 0;
}

.vaF02FJcvbQm8612Ejv4 {
  font: inherit;
}

@media (min-width: 992px) {
  .vaF02FJcvbQm8612Ejv4 {
    height: 70vh;
  }
}

.vaF02FJcvbQm8612Ejv4 .f2GfeKHHOe0dBt0lWdpk {
  background-color: #fff;
  background-color: var(--colour-white);
  box-shadow: 0 3px 5px 0 #ced0d2;
  box-shadow: 0 3px 5px 0 var(--colour-grey);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
}

.vaF02FJcvbQm8612Ejv4 .f2GfeKHHOe0dBt0lWdpk h1 {
  font-size: 1.4em;
  padding: 0.5em 0;
  text-align: center;
}

@media (min-width: 321px) {
  .vaF02FJcvbQm8612Ejv4 .f2GfeKHHOe0dBt0lWdpk h1 {
    font-size: 1.8em;
  }
}

.vaF02FJcvbQm8612Ejv4 .DLql3EbomW8ienD_YcxM {
  display: flex;
  flex-direction: column;
  justify-content: normal;
  min-height: calc(100vh - 80px);
  overflow: auto;
  padding: 1em;
  position: relative;
}

@media (min-width: 992px) {
  .vaF02FJcvbQm8612Ejv4 .DLql3EbomW8ienD_YcxM {
    height: calc(70vh - 80px);
    min-height: 0;
    min-height: auto;
  }
}

.vaF02FJcvbQm8612Ejv4 .DLql3EbomW8ienD_YcxM .MHy6bTDZkTWSg7sb6T_A {
  display: flex;
  flex-direction: column;
  gap: 1em;
  justify-content: center;
  margin: 1em 0;
}

@media (min-width: 768px) {
  .vaF02FJcvbQm8612Ejv4 .DLql3EbomW8ienD_YcxM .MHy6bTDZkTWSg7sb6T_A {
    flex-direction: row-reverse;
  }
}

.EKpmUwU1yda7Qzoj_9De h4,
.EKpmUwU1yda7Qzoj_9De p {
  margin: 1em;
}

.EKpmUwU1yda7Qzoj_9De .OQaytlX0fWVPUVd4E1fn {
  font-style: italic;
  margin: 1em 1em 0.5em;
}

.EKpmUwU1yda7Qzoj_9De .yyjSKNsGtzbXhX5iGBMf {
  margin: 1em 1em 0.5em;
}

.EKpmUwU1yda7Qzoj_9De .DhjvOuCfpWzZzf8saSzw {
  display: flex;
  flex-direction: column;
}

.EKpmUwU1yda7Qzoj_9De .jkrYrOPNkSL7fkdfG1jW {
  align-items: center;
  display: flex;
  justify-content: center;
}

.EKpmUwU1yda7Qzoj_9De .jkrYrOPNkSL7fkdfG1jW button {
  background-color: var(--colour-white);
  margin: 1em 0.5em;
  min-width: 5.5em;
}

._AwXSWGFRxQhCqT5prOp {
  display: flex;
  justify-content: space-between;
  position: relative;
}

@supports (-webkit-touch-callout: none) {
  ._AwXSWGFRxQhCqT5prOp .J3j894DANrNOeYHjec41 {
    -webkit-appearance: none;
  }

  ._AwXSWGFRxQhCqT5prOp .J3j894DANrNOeYHjec41:before {
    content: "dd/mm/yyyy";
  }

  ._AwXSWGFRxQhCqT5prOp .vn0MDUvjb8tSVun98l9R {
    -webkit-appearance: none;
  }

  ._AwXSWGFRxQhCqT5prOp .vn0MDUvjb8tSVun98l9R:after {
    content: none;
  }
}

._AwXSWGFRxQhCqT5prOp i {
  position: absolute;
  right: 1em;
  top: 0.7em;
}

@supports (-webkit-touch-callout: none) {
  ._AwXSWGFRxQhCqT5prOp i {
    display: block;
  }
}
