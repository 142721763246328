:root {
  --colour-white: #fff;
  --colour-black: #000;
  --colour-lightest-grey: #f6f6f6;
  --colour-light-grey: #e4e4e4;
  --colour-grey: #ced0d2;
  --colour-medium-grey: #e7e8e8;
  --colour-darkish-grey: #8a979e;
  --colour-dark-grey: #596267;
  --colour-dark-grey-hover: #606a70;
  --colour-dark-grey-hover-text: #758188;
  --colour-darker-grey: #363d40;
  --colour-darkist-grey: #292b2c;
  --colour-brand-green: #afd7bb;
  --colour-brand-darkened-green: #7cbe90;
  --colour-brand-dark-green: #4f9e67;
  --colour-brand-green-hover: #b3d9be;
  --colour-brand-green-hover-text: #3e7c51;
  --colour-brand-light-blue: #e3f1f7;
  --colour-brand-blue: #94cbe0;
  --colour-brand-pink: #eecde4;
  --colour-brand-dark-pink: #d9bad0;
  --colour-brand-salmon-pink: #f6ddd8;
  --colour-brand-light-yellow: #ecedde;
  --colour-brand-peach: #f3c5ad;
  --colour-brand-light-peach: #fbeee7;
  --colour-brand-teal: #4d9699;
  --colour-brand-teal-two: #78a3ab;
  --colour-brand-teal-three: #82acb1;
  --colour-brand-teal-four: #d0e9e9;
  --colour-brand-teal-five: #ebf1f2;
  --colour-brand-cream: #fbf2ed;
  --colour-brand-cream-two: #f6dec7;
  --colour-brand-cream-three: #faeee5;
  --colour-brand-cream-four: #fef2db;
  --colour-brand-darker-cream: #f6eee8;
  --colour-brand-brown: #c89c88;
  --colour-brand-purple: #977eb8;
  --colour-brand-light-purple: #e2e0f5;
  --colour-warning: #f87070;
  --colour-warning-hover: #f98d8d;
  --colour-success: #c9f1d5;
  --colour-advisory: #fcf8e3;
  --colour-advisory-border: #faf2cc;
  --colour-advisory-text: #8a6d3b;
  --border-radius: 3px;
  --border-circle: 50px;
  --font: 18px/1.5 "BrandonGrotesque-Regular", open_sansregular, -apple-system,
    system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans,
    sans-serif, blinkmacsystemfont, "Segoe UI", roboto, "Helvetica Neue", arial,
    sans-serif;
  --font-light: 18px/1.5 "BrandonGrotesque-Light", open_sansregular,
    -apple-system, system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell,
    Noto Sans, sans-serif, blinkmacsystemfont, "Segoe UI", roboto,
    "Helvetica Neue", arial, sans-serif;
  --font-medium: 18px/1.5 "BrandonGrotesque-Medium", open_sansregular,
    -apple-system, system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell,
    Noto Sans, sans-serif, blinkmacsystemfont, "Segoe UI", roboto,
    "Helvetica Neue", arial, sans-serif;
  --font-bold: 18px/1.5 "BrandonGrotesque-Bold", open_sansregular, -apple-system,
    system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans,
    sans-serif, blinkmacsystemfont, "Segoe UI", roboto, "Helvetica Neue", arial,
    sans-serif;
  --transition: 150ms ease-in-out;
  --underline-offset: 0.2em;
}

.zvTOk2V0UJanoNB9dSfh {
  background-color: #ffc0cb;
  display: flex;
  justify-content: center;
  margin: -20px -15px 1em;
  padding: 1em;
}

@media (min-width: 576px) {
  .zvTOk2V0UJanoNB9dSfh {
    margin: 1em 0;
  }
}

.zvTOk2V0UJanoNB9dSfh h1 {
  color: #fff;
  color: var(--colour-white);
  font-size: 1.6em;
  margin-right: 0.5em;
  text-align: center;
}

.zvTOk2V0UJanoNB9dSfh img {
  width: 3em;
}

.fKo74OjyrI679xRKQ4Bu {
  grid-gap: 1em;
  display: grid;
  gap: 1em;
  margin: 0 1em;
}

@media (min-width: 576px) {
  .fKo74OjyrI679xRKQ4Bu {
    margin: 0 2em;
  }
}

@media (min-width: 768px) {
  .fKo74OjyrI679xRKQ4Bu {
    column-gap: 2em;
    grid-template-columns: repeat(2, 1fr);
    margin: 1.5em 0 0.5em;
  }
}

@media (min-width: 992px) {
  .fKo74OjyrI679xRKQ4Bu {
    column-gap: 1em;
  }
}

.fKo74OjyrI679xRKQ4Bu .UG7Bl4cIPQ6woiN4MYtk {
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
}

@media (min-width: 768px) {
  .fKo74OjyrI679xRKQ4Bu .UG7Bl4cIPQ6woiN4MYtk {
    align-items: flex-start;
    row-gap: 0;
    text-align: left;
    text-align: initial;
  }

  .fKo74OjyrI679xRKQ4Bu .UG7Bl4cIPQ6woiN4MYtk:first-child {
    grid-area: 1/1/2/2;
  }

  .fKo74OjyrI679xRKQ4Bu .UG7Bl4cIPQ6woiN4MYtk:nth-child(2) {
    grid-area: 2/1/3/2;
    margin-top: 1em;
  }

  .fKo74OjyrI679xRKQ4Bu .UG7Bl4cIPQ6woiN4MYtk:nth-child(3) {
    grid-area: 1/2/3/3;
  }

  .fKo74OjyrI679xRKQ4Bu .UG7Bl4cIPQ6woiN4MYtk:nth-child(4) {
    grid-area: 3/1/4/3;
    margin: 1em;
  }
}

.fKo74OjyrI679xRKQ4Bu .UG7Bl4cIPQ6woiN4MYtk:nth-child(4) {
  align-items: center;
  justify-content: center;
  margin-bottom: 1em;
}

@media (max-width: 767px) {
  .fKo74OjyrI679xRKQ4Bu .UG7Bl4cIPQ6woiN4MYtk:first-child:after,
  .fKo74OjyrI679xRKQ4Bu .UG7Bl4cIPQ6woiN4MYtk:nth-child(2):after {
    background-color: #e4e4e4;
    background-color: var(--colour-light-grey);
    content: "";
    height: 1px;
    margin-top: 1em;
    width: 40%;
  }
}

.fKo74OjyrI679xRKQ4Bu .UG7Bl4cIPQ6woiN4MYtk h2 {
  font-size: 1.25em;
  margin-bottom: 0.4em;
}

.fKo74OjyrI679xRKQ4Bu .UG7Bl4cIPQ6woiN4MYtk a {
  margin: 0.3em 0;
}

.uMc953OVRlNHKqffF4XW {
  display: flex;
  justify-content: center;
}

.KbUUDfXOqoUP1V3vd_bE {
  grid-gap: 1em;
  display: grid;
  gap: 1em;
}

@media (min-width: 992px) {
  .KbUUDfXOqoUP1V3vd_bE {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 0.1fr 1.8fr;
  }

  .KbUUDfXOqoUP1V3vd_bE ._QxMBiWT3AtKg5VaaK0e {
    grid-area: 1/1/3/1;
  }
}

.KbUUDfXOqoUP1V3vd_bE .aaU1KAN2ZbbHEX80pqMF {
  grid-area: 1/1/1/1;
}

@media (min-width: 992px) {
  .KbUUDfXOqoUP1V3vd_bE .aaU1KAN2ZbbHEX80pqMF {
    grid-area: 1/2/1/2;
  }
}

.KbUUDfXOqoUP1V3vd_bE .aaU1KAN2ZbbHEX80pqMF .zYvXFWHKZHKbSHU8gmYH {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1em;
}

@media (min-width: 768px) {
  .KbUUDfXOqoUP1V3vd_bE .aaU1KAN2ZbbHEX80pqMF .zYvXFWHKZHKbSHU8gmYH {
    flex-direction: row;
    gap: 2em;
    justify-content: center;
    padding-bottom: 0;
  }
}

.KbUUDfXOqoUP1V3vd_bE .aaU1KAN2ZbbHEX80pqMF .zYvXFWHKZHKbSHU8gmYH span {
  display: flex;
  flex-direction: column;
  gap: 1em;
  text-align: center;
}

.KbUUDfXOqoUP1V3vd_bE .aaU1KAN2ZbbHEX80pqMF .zYvXFWHKZHKbSHU8gmYH span p {
  font: 18px/1.5 BrandonGrotesque-Bold, open_sansregular, -apple-system,
    system-ui, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif,
    blinkmacsystemfont, roboto, Helvetica Neue, arial;
  font: var(--font-bold);
  margin-bottom: 0 !important;
}

@media (min-width: 992px) {
  .KbUUDfXOqoUP1V3vd_bE .II43C4fpkvTqqKAgUMJN {
    grid-area: 2/2/2/2;
  }
}
