.rmdp-input{
    width: 10px;
}

.rmdp-container {
    position: absolute;
    
}

.rmdp-input{
    border: none;
    display: none;
}