:root {
  --colour-white: #fff;
  --colour-black: #000;
  --colour-lightest-grey: #f6f6f6;
  --colour-light-grey: #e4e4e4;
  --colour-grey: #ced0d2;
  --colour-medium-grey: #e7e8e8;
  --colour-darkish-grey: #8a979e;
  --colour-dark-grey: #596267;
  --colour-dark-grey-hover: #606a70;
  --colour-dark-grey-hover-text: #758188;
  --colour-darker-grey: #363d40;
  --colour-darkist-grey: #292b2c;
  --colour-brand-green: #afd7bb;
  --colour-brand-darkened-green: #7cbe90;
  --colour-brand-dark-green: #4f9e67;
  --colour-brand-green-hover: #b3d9be;
  --colour-brand-green-hover-text: #3e7c51;
  --colour-brand-light-blue: #e3f1f7;
  --colour-brand-blue: #94cbe0;
  --colour-brand-pink: #eecde4;
  --colour-brand-dark-pink: #d9bad0;
  --colour-brand-salmon-pink: #f6ddd8;
  --colour-brand-light-yellow: #ecedde;
  --colour-brand-peach: #f3c5ad;
  --colour-brand-light-peach: #fbeee7;
  --colour-brand-teal: #4d9699;
  --colour-brand-teal-two: #78a3ab;
  --colour-brand-teal-three: #82acb1;
  --colour-brand-teal-four: #d0e9e9;
  --colour-brand-teal-five: #ebf1f2;
  --colour-brand-cream: #fbf2ed;
  --colour-brand-cream-two: #f6dec7;
  --colour-brand-cream-three: #faeee5;
  --colour-brand-cream-four: #fef2db;
  --colour-brand-darker-cream: #f6eee8;
  --colour-brand-brown: #c89c88;
  --colour-brand-purple: #977eb8;
  --colour-brand-light-purple: #e2e0f5;
  --colour-warning: #f87070;
  --colour-warning-hover: #f98d8d;
  --colour-success: #c9f1d5;
  --colour-advisory: #fcf8e3;
  --colour-advisory-border: #faf2cc;
  --colour-advisory-text: #8a6d3b;
  --border-radius: 3px;
  --border-circle: 50px;
  --font: 18px/1.5 "BrandonGrotesque-Regular", open_sansregular, -apple-system,
    system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans,
    sans-serif, blinkmacsystemfont, "Segoe UI", roboto, "Helvetica Neue", arial,
    sans-serif;
  --font-light: 18px/1.5 "BrandonGrotesque-Light", open_sansregular,
    -apple-system, system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell,
    Noto Sans, sans-serif, blinkmacsystemfont, "Segoe UI", roboto,
    "Helvetica Neue", arial, sans-serif;
  --font-medium: 18px/1.5 "BrandonGrotesque-Medium", open_sansregular,
    -apple-system, system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell,
    Noto Sans, sans-serif, blinkmacsystemfont, "Segoe UI", roboto,
    "Helvetica Neue", arial, sans-serif;
  --font-bold: 18px/1.5 "BrandonGrotesque-Bold", open_sansregular, -apple-system,
    system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans,
    sans-serif, blinkmacsystemfont, "Segoe UI", roboto, "Helvetica Neue", arial,
    sans-serif;
  --transition: 150ms ease-in-out;
  --underline-offset: 0.2em;
}

.a85u87S0FXgB7cibbD59
  .eSp0Ptdp6adGzeQYcUAw
  .KNrWgq6VMkyQJVqHYcOb
  .qZVNiFbGa1SR6gu8cQK6
  a
  .dAj0pfpxI_WUNJnnNcZi {
  display: block;
  position: relative;
  text-align: right;
  width: 70%;
}

@media (min-width: 768px) and (max-width: 991px) {
  .a85u87S0FXgB7cibbD59
    .eSp0Ptdp6adGzeQYcUAw
    .KNrWgq6VMkyQJVqHYcOb
    .qZVNiFbGa1SR6gu8cQK6
    a
    .dAj0pfpxI_WUNJnnNcZi {
    margin-left: 1em;
  }
}

@media (min-width: 992px) {
  .a85u87S0FXgB7cibbD59
    .eSp0Ptdp6adGzeQYcUAw
    .KNrWgq6VMkyQJVqHYcOb
    .qZVNiFbGa1SR6gu8cQK6
    a
    .dAj0pfpxI_WUNJnnNcZi {
    margin-left: 0.5em;
  }
}

.a85u87S0FXgB7cibbD59
  .eSp0Ptdp6adGzeQYcUAw
  .KNrWgq6VMkyQJVqHYcOb
  .qZVNiFbGa1SR6gu8cQK6
  a
  .dAj0pfpxI_WUNJnnNcZi:before {
  background: linear-gradient(transparent, #ced0d2);
  background: linear-gradient(transparent, var(--colour-grey));
  bottom: 1px;
  content: "";
  height: 40px;
  left: 15px;
  position: absolute;
  transform: skewX(40deg);
  width: 90%;
}

.a85u87S0FXgB7cibbD59
  .eSp0Ptdp6adGzeQYcUAw
  .KNrWgq6VMkyQJVqHYcOb
  .qZVNiFbGa1SR6gu8cQK6
  a
  .dAj0pfpxI_WUNJnnNcZi:after {
  background: linear-gradient(
    to left top,
    #ced0d2,
    #ced0d2 48%,
    transparent 50%,
    transparent
  );
  background: linear-gradient(
    to left top,
    var(--colour-grey),
    var(--colour-grey) 48%,
    transparent 50%,
    transparent
  );
  content: "";
  height: 20px;
  left: 30px;
  position: absolute;
  top: 0;
  width: 70%;
}

.a85u87S0FXgB7cibbD59
  .eSp0Ptdp6adGzeQYcUAw
  .KNrWgq6VMkyQJVqHYcOb
  .qZVNiFbGa1SR6gu8cQK6
  a
  .dAj0pfpxI_WUNJnnNcZi
  img {
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.1);
  height: auto;
  margin-left: 32px;
  margin-top: 20px;
  position: relative;
}

.a85u87S0FXgB7cibbD59 .YUryRFnU6UiPTD_YF6V7 {
  background-color: #78a3ab;
  background-color: var(--colour-brand-teal-two);
  border-radius: 3px;
  border-radius: var(--border-radius);
  color: #fff;
  color: var(--colour-white);
  font-size: 1em;
  padding: 0.75em;
}

.a85u87S0FXgB7cibbD59 .YUryRFnU6UiPTD_YF6V7 .lJY7MXoKjidlBAsi3Xxg {
  color: #fff;
  color: var(--colour-white);
  font: 18px/1.5 BrandonGrotesque-Bold, open_sansregular, -apple-system,
    system-ui, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif,
    blinkmacsystemfont, roboto, Helvetica Neue, arial;
  font: var(--font-bold);
  font-size: 1.5em;
  margin-bottom: 0.25em;
}

.a85u87S0FXgB7cibbD59
  .YUryRFnU6UiPTD_YF6V7
  .lJY7MXoKjidlBAsi3Xxg
  .XLFPjQvLCuJisGnaJ2Wa {
  font: 18px/1.5 BrandonGrotesque-Regular, open_sansregular, -apple-system,
    system-ui, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif,
    blinkmacsystemfont, roboto, Helvetica Neue, arial;
  font: var(--font);
  font-size: 1rem;
  margin-left: 10px;
  -webkit-text-decoration: underline;
  text-decoration: underline;
  text-underline-offset: 0.2em;
  text-underline-offset: var(--underline-offset);
}

.a85u87S0FXgB7cibbD59 .eSp0Ptdp6adGzeQYcUAw {
  grid-gap: 1em;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 1em;
}

@media (min-width: 992px) {
  .a85u87S0FXgB7cibbD59 .eSp0Ptdp6adGzeQYcUAw {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.a85u87S0FXgB7cibbD59 .eSp0Ptdp6adGzeQYcUAw .KNrWgq6VMkyQJVqHYcOb {
  transform: translateZ(0);
}

.a85u87S0FXgB7cibbD59
  .eSp0Ptdp6adGzeQYcUAw
  .KNrWgq6VMkyQJVqHYcOb
  .qZVNiFbGa1SR6gu8cQK6 {
  height: 100%;
  width: 100%;
}

.a85u87S0FXgB7cibbD59
  .eSp0Ptdp6adGzeQYcUAw
  .KNrWgq6VMkyQJVqHYcOb
  .qZVNiFbGa1SR6gu8cQK6:before {
  content: "";
  display: block;
  padding-top: 100%;
}

.a85u87S0FXgB7cibbD59
  .eSp0Ptdp6adGzeQYcUAw
  .KNrWgq6VMkyQJVqHYcOb
  .qZVNiFbGa1SR6gu8cQK6
  a {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.a85u87S0FXgB7cibbD59
  .eSp0Ptdp6adGzeQYcUAw
  .KNrWgq6VMkyQJVqHYcOb
  .qZVNiFbGa1SR6gu8cQK6
  a
  .dAj0pfpxI_WUNJnnNcZi {
  margin-left: -32px;
}

.a85u87S0FXgB7cibbD59
  .eSp0Ptdp6adGzeQYcUAw
  .KNrWgq6VMkyQJVqHYcOb
  .qZVNiFbGa1SR6gu8cQK6
  a
  .dAj0pfpxI_WUNJnnNcZi
  img {
  width: 90%;
}
