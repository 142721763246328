:root {
    --colour-white: #fff;
    --colour-black: #000;
    --colour-lightest-grey: #f6f6f6;
    --colour-light-grey: #e4e4e4;
    --colour-grey: #ced0d2;
    --colour-medium-grey: #e7e8e8;
    --colour-darkish-grey: #8a979e;
    --colour-dark-grey: #596267;
    --colour-dark-grey-hover: #606a70;
    --colour-dark-grey-hover-text: #758188;
    --colour-darker-grey: #363d40;
    --colour-darkist-grey: #292b2c;
    --colour-brand-green: #afd7bb;
    --colour-brand-darkened-green: #7cbe90;
    --colour-brand-dark-green: #4f9e67;
    --colour-brand-green-hover: #b3d9be;
    --colour-brand-green-hover-text: #3e7c51;
    --colour-brand-light-blue: #e3f1f7;
    --colour-brand-blue: #94cbe0;
    --colour-brand-pink: #eecde4;
    --colour-brand-dark-pink: #d9bad0;
    --colour-brand-salmon-pink: #f6ddd8;
    --colour-brand-light-yellow: #ecedde;
    --colour-brand-peach: #f3c5ad;
    --colour-brand-light-peach: #fbeee7;
    --colour-brand-teal: #4d9699;
    --colour-brand-teal-two: #78a3ab;
    --colour-brand-teal-three: #82acb1;
    --colour-brand-teal-four: #d0e9e9;
    --colour-brand-teal-five: #ebf1f2;
    --colour-brand-cream: #fbf2ed;
    --colour-brand-cream-two: #f6dec7;
    --colour-brand-cream-three: #faeee5;
    --colour-brand-cream-four: #fef2db;
    --colour-brand-darker-cream: #f6eee8;
    --colour-brand-brown: #c89c88;
    --colour-brand-purple: #977eb8;
    --colour-brand-light-purple: #e2e0f5;
    --colour-warning: #f87070;
    --colour-warning-hover: #f98d8d;
    --colour-success: #c9f1d5;
    --colour-advisory: #fcf8e3;
    --colour-advisory-border: #faf2cc;
    --colour-advisory-text: #8a6d3b;
    --border-radius: 3px;
    --border-circle: 50px;
    --font: 18px/1.5 "BrandonGrotesque-Regular",open_sansregular,-apple-system,system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,blinkmacsystemfont,"Segoe UI",roboto,"Helvetica Neue",arial,sans-serif;
    --font-light: 18px/1.5 "BrandonGrotesque-Light",open_sansregular,-apple-system,system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,blinkmacsystemfont,"Segoe UI",roboto,"Helvetica Neue",arial,sans-serif;
    --font-medium: 18px/1.5 "BrandonGrotesque-Medium",open_sansregular,-apple-system,system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,blinkmacsystemfont,"Segoe UI",roboto,"Helvetica Neue",arial,sans-serif;
    --font-bold: 18px/1.5 "BrandonGrotesque-Bold",open_sansregular,-apple-system,system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,blinkmacsystemfont,"Segoe UI",roboto,"Helvetica Neue",arial,sans-serif;
    --transition: 150ms ease-in-out;
    --underline-offset: 0.2em
}

.RE36GM4qWntmSzvbDOwH {
    padding-top: 1em
}

.RE36GM4qWntmSzvbDOwH .hjLMEMq9Zi8hzcK0av3L {
    cursor: pointer;
    margin-bottom: 1.5em
}

.RE36GM4qWntmSzvbDOwH .hjLMEMq9Zi8hzcK0av3L a:hover {
    -webkit-text-decoration: none;
    text-decoration: none
}

.RE36GM4qWntmSzvbDOwH .hjLMEMq9Zi8hzcK0av3L .S8zrtVx3ESiSO92lkVob {
    display: flex;
    flex-direction: column
}

@media(min-width: 992px) {
    .RE36GM4qWntmSzvbDOwH .hjLMEMq9Zi8hzcK0av3L .S8zrtVx3ESiSO92lkVob {
        flex-direction:row
    }
}

.RE36GM4qWntmSzvbDOwH .hjLMEMq9Zi8hzcK0av3L .S8zrtVx3ESiSO92lkVob .xK09C15epzbXMoY9ioyT {
    background-image: url(https://media.bunches.co.uk/content_pages/refer-a-friend-hero-sunflowers.jpg);
    background-position: 50%;
    background-size: cover;
    height: 16em
}

@media(min-width: 992px) {
    .RE36GM4qWntmSzvbDOwH .hjLMEMq9Zi8hzcK0av3L .S8zrtVx3ESiSO92lkVob .xK09C15epzbXMoY9ioyT {
        flex:25%;
        height: auto
    }
}

.RE36GM4qWntmSzvbDOwH .hjLMEMq9Zi8hzcK0av3L .S8zrtVx3ESiSO92lkVob .EF4qtoOMnELXZZckMwj6 {
    background-color: #ffc0cb;
    padding: 1.2em;
    text-align: center
}

@media(min-width: 321px) {
    .RE36GM4qWntmSzvbDOwH .hjLMEMq9Zi8hzcK0av3L .S8zrtVx3ESiSO92lkVob .EF4qtoOMnELXZZckMwj6 {
        padding:1.5em
    }
}

@media(min-width: 992px) {
    .RE36GM4qWntmSzvbDOwH .hjLMEMq9Zi8hzcK0av3L .S8zrtVx3ESiSO92lkVob .EF4qtoOMnELXZZckMwj6 {
        flex:50%;
        padding: 3em;
        text-align: center
    }
}

@media(min-width: 1200px) {
    .RE36GM4qWntmSzvbDOwH .hjLMEMq9Zi8hzcK0av3L .S8zrtVx3ESiSO92lkVob .EF4qtoOMnELXZZckMwj6 {
        padding:4em
    }
}

.RE36GM4qWntmSzvbDOwH .hjLMEMq9Zi8hzcK0av3L .S8zrtVx3ESiSO92lkVob .EF4qtoOMnELXZZckMwj6 h1 {
    color: #fff;
    color: var(--colour-white);
    font: 18px/1.5 BrandonGrotesque-Bold,open_sansregular,-apple-system,system-ui,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,blinkmacsystemfont,roboto,Helvetica Neue,arial;
    font: var(--font-bold);
    font-size: 1.2em
}

@media(min-width: 992px) {
    .RE36GM4qWntmSzvbDOwH .hjLMEMq9Zi8hzcK0av3L .S8zrtVx3ESiSO92lkVob .EF4qtoOMnELXZZckMwj6 h1 {
        font-size:1.6em
    }
}

.RE36GM4qWntmSzvbDOwH .hjLMEMq9Zi8hzcK0av3L .S8zrtVx3ESiSO92lkVob .EF4qtoOMnELXZZckMwj6 p {
    color: #fff;
    color: var(--colour-white);
    font: 18px/1.5 BrandonGrotesque-Regular,open_sansregular,-apple-system,system-ui,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,blinkmacsystemfont,roboto,Helvetica Neue,arial;
    font: var(--font);
    font-size: 1rem;
    margin: 1em 0 1.5em
}

@media(min-width: 768px)and (max-width:991px) {
    .RE36GM4qWntmSzvbDOwH .hjLMEMq9Zi8hzcK0av3L .S8zrtVx3ESiSO92lkVob .EF4qtoOMnELXZZckMwj6 p {
        padding:0 6.5em
    }
}

@media(min-width: 992px) {
    .RE36GM4qWntmSzvbDOwH .hjLMEMq9Zi8hzcK0av3L .S8zrtVx3ESiSO92lkVob .EF4qtoOMnELXZZckMwj6 p {
        font-size:1.2rem;
        margin: 1.5em 0 2em
    }
}

.RE36GM4qWntmSzvbDOwH .hjLMEMq9Zi8hzcK0av3L .S8zrtVx3ESiSO92lkVob .EF4qtoOMnELXZZckMwj6 .JwbcXSAoXyujMLRDHx7D {
    background-color: #fff;
    background-color: var(--colour-white);
    color: #363d40;
    color: var(--colour-darker-grey);
    display: inline-block;
    font: 18px/1.5 BrandonGrotesque-Bold,open_sansregular,-apple-system,system-ui,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,blinkmacsystemfont,roboto,Helvetica Neue,arial;
    font: var(--font-bold);
    font-size: .79rem;
    letter-spacing: .1em;
    padding: 1em;
    text-transform: uppercase
}

.RE36GM4qWntmSzvbDOwH .hjLMEMq9Zi8hzcK0av3L .S8zrtVx3ESiSO92lkVob .EF4qtoOMnELXZZckMwj6 .JwbcXSAoXyujMLRDHx7D:hover {
    -webkit-text-decoration: underline;
    text-decoration: underline;
    text-underline-offset: .2em;
    text-underline-offset: var(--underline-offset)
}

@media(min-width: 992px) {
    .RE36GM4qWntmSzvbDOwH .hjLMEMq9Zi8hzcK0av3L .S8zrtVx3ESiSO92lkVob .EF4qtoOMnELXZZckMwj6 .JwbcXSAoXyujMLRDHx7D {
        display:flex;
        font-size: .9rem;
        justify-content: center
    }
}

@media(min-width: 1200px) {
    .RE36GM4qWntmSzvbDOwH .hjLMEMq9Zi8hzcK0av3L .S8zrtVx3ESiSO92lkVob .EF4qtoOMnELXZZckMwj6 .JwbcXSAoXyujMLRDHx7D {
        font-size:1rem
    }
}

.RE36GM4qWntmSzvbDOwH .XwmNYxMiaTyFhX4uUvMe {
    align-items: center;
    display: flex;
    flex-direction: column
}

.RE36GM4qWntmSzvbDOwH .XwmNYxMiaTyFhX4uUvMe h3 {
    margin: 1em
}

.RE36GM4qWntmSzvbDOwH .XwmNYxMiaTyFhX4uUvMe a {
    margin: 0 0 2em
}

.RE36GM4qWntmSzvbDOwH .XwmNYxMiaTyFhX4uUvMe .op5jVVCRDFvEkEENVWML {
    grid-gap: 1em;
    display: grid;
    gap: 1em;
    grid-template-columns: 1fr;
    margin: 1em 0 2em
}

@media(min-width: 768px) {
    .RE36GM4qWntmSzvbDOwH .XwmNYxMiaTyFhX4uUvMe .op5jVVCRDFvEkEENVWML {
        grid-template-columns:repeat(3,1fr)
    }
}

.RE36GM4qWntmSzvbDOwH .XwmNYxMiaTyFhX4uUvMe .op5jVVCRDFvEkEENVWML .ejvCftzg8F6l3pHNBRw_ {
    align-items: center;
    background-color: #f6f6f6;
    background-color: var(--colour-lightest-grey);
    display: flex;
    flex-direction: column;
    padding: 1em 2em;
    text-align: center
}

@media(min-width: 768px) {
    .RE36GM4qWntmSzvbDOwH .XwmNYxMiaTyFhX4uUvMe .op5jVVCRDFvEkEENVWML .ejvCftzg8F6l3pHNBRw_ {
        padding:2em
    }
}

.RE36GM4qWntmSzvbDOwH .XwmNYxMiaTyFhX4uUvMe .op5jVVCRDFvEkEENVWML .ejvCftzg8F6l3pHNBRw_ .bR1stVJIEnCJc24l4vTL {
    align-items: center;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    margin-bottom: 1em;
    width: 100%
}

@media(min-width: 768px) {
    .RE36GM4qWntmSzvbDOwH .XwmNYxMiaTyFhX4uUvMe .op5jVVCRDFvEkEENVWML .ejvCftzg8F6l3pHNBRw_ .bR1stVJIEnCJc24l4vTL {
        display:flex;
        flex-direction: column;
        margin-bottom: 0
    }
}

.RE36GM4qWntmSzvbDOwH .XwmNYxMiaTyFhX4uUvMe .op5jVVCRDFvEkEENVWML .ejvCftzg8F6l3pHNBRw_ .bR1stVJIEnCJc24l4vTL h4 {
    letter-spacing: .1em;
    text-transform: uppercase
}

.RE36GM4qWntmSzvbDOwH .XwmNYxMiaTyFhX4uUvMe .op5jVVCRDFvEkEENVWML .ejvCftzg8F6l3pHNBRw_ .bR1stVJIEnCJc24l4vTL .WyXltSzfMKCMzVKKQLMg {
    align-items: center;
    background-color: #4d9699;
    background-color: var(--colour-brand-teal);
    border-radius: 50%;
    display: flex;
    height: 3em;
    justify-content: center;
    margin-right: 1em;
    width: 3em
}

@media(min-width: 768px) {
    .RE36GM4qWntmSzvbDOwH .XwmNYxMiaTyFhX4uUvMe .op5jVVCRDFvEkEENVWML .ejvCftzg8F6l3pHNBRw_ .bR1stVJIEnCJc24l4vTL .WyXltSzfMKCMzVKKQLMg {
        margin:1em 0
    }
}

.RE36GM4qWntmSzvbDOwH .XwmNYxMiaTyFhX4uUvMe .op5jVVCRDFvEkEENVWML .ejvCftzg8F6l3pHNBRw_ .bR1stVJIEnCJc24l4vTL .WyXltSzfMKCMzVKKQLMg i {
    color: #fff;
    color: var(--colour-white);
    font-size: 1.4em
}

.RE36GM4qWntmSzvbDOwH .XwmNYxMiaTyFhX4uUvMe .op5jVVCRDFvEkEENVWML .ejvCftzg8F6l3pHNBRw_ .bR1stVJIEnCJc24l4vTL .WyXltSzfMKCMzVKKQLMg .s8Gqa3qRBQ6twGRG9UMb {
    margin-top: 5px
}

.RE36GM4qWntmSzvbDOwH .kEH40knLIFcN61pYoMav {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 2em 0 3em
}

@media(min-width: 992px) {
    .RE36GM4qWntmSzvbDOwH .kEH40knLIFcN61pYoMav {
        margin:1em 0
    }
}

.RE36GM4qWntmSzvbDOwH .kEH40knLIFcN61pYoMav .KtRuK8LmdTWK_6fTDbW7 {
    display: flex;
    flex-direction: column-reverse;
    margin: 0 -15px;
    position: relative
}

@media(min-width: 576px) {
    .RE36GM4qWntmSzvbDOwH .kEH40knLIFcN61pYoMav .KtRuK8LmdTWK_6fTDbW7 {
        margin:initial
    }
}

@media(min-width: 768px) {
    .RE36GM4qWntmSzvbDOwH .kEH40knLIFcN61pYoMav .KtRuK8LmdTWK_6fTDbW7 {
        align-items:center;
        flex-direction: row;
        justify-content: space-between;
        width: 100%
    }
}

.RE36GM4qWntmSzvbDOwH .kEH40knLIFcN61pYoMav .KtRuK8LmdTWK_6fTDbW7 .mj64qjZoAz5DPm4_fJhF {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 1em;
    width: 100%;
    z-index: 2
}

@media(min-width: 768px) {
    .RE36GM4qWntmSzvbDOwH .kEH40knLIFcN61pYoMav .KtRuK8LmdTWK_6fTDbW7 .mj64qjZoAz5DPm4_fJhF {
        margin:0
    }
}

.RE36GM4qWntmSzvbDOwH .kEH40knLIFcN61pYoMav .KtRuK8LmdTWK_6fTDbW7 .mj64qjZoAz5DPm4_fJhF .NZm3GSRHqjo_Ii5_o940 {
    bottom: 25%;
    height: 30%;
    left: 0;
    position: absolute;
    z-index: 2
}

@media(min-width: 768px) {
    .RE36GM4qWntmSzvbDOwH .kEH40knLIFcN61pYoMav .KtRuK8LmdTWK_6fTDbW7 .mj64qjZoAz5DPm4_fJhF .NZm3GSRHqjo_Ii5_o940 {
        height:28%;
        position: static;
        width: 28%
    }
}

@media(min-width: 992px) {
    .RE36GM4qWntmSzvbDOwH .kEH40knLIFcN61pYoMav .KtRuK8LmdTWK_6fTDbW7 .mj64qjZoAz5DPm4_fJhF .NZm3GSRHqjo_Ii5_o940 {
        height:25%;
        width: 25%
    }
}

.RE36GM4qWntmSzvbDOwH .kEH40knLIFcN61pYoMav .KtRuK8LmdTWK_6fTDbW7 .mj64qjZoAz5DPm4_fJhF h3 {
    margin: 1em
}

@media(min-width: 768px)and (max-width:991px) {
    .RE36GM4qWntmSzvbDOwH .kEH40knLIFcN61pYoMav .KtRuK8LmdTWK_6fTDbW7 .mj64qjZoAz5DPm4_fJhF h3 {
        margin:.5em
    }
}

@media(min-width: 992px) {
    .RE36GM4qWntmSzvbDOwH .kEH40knLIFcN61pYoMav .KtRuK8LmdTWK_6fTDbW7 .mj64qjZoAz5DPm4_fJhF h3 {
        margin-top:.5em
    }
}

.RE36GM4qWntmSzvbDOwH .kEH40knLIFcN61pYoMav .KtRuK8LmdTWK_6fTDbW7 .mj64qjZoAz5DPm4_fJhF a {
    padding: .5em 1em
}

.RE36GM4qWntmSzvbDOwH .kEH40knLIFcN61pYoMav .KtRuK8LmdTWK_6fTDbW7 .FKL7oAmhdQ0ARrWG5Mej {
    background-color: #4d9699;
    background-color: var(--colour-brand-teal);
    bottom: 35%;
    left: 0;
    opacity: .15;
    position: absolute;
    right: 0;
    top: 8%
}

@media(min-width: 768px) {
    .RE36GM4qWntmSzvbDOwH .kEH40knLIFcN61pYoMav .KtRuK8LmdTWK_6fTDbW7 .FKL7oAmhdQ0ARrWG5Mej {
        bottom:10%;
        left: 0;
        right: 0;
        top: 10%
    }
}

.RE36GM4qWntmSzvbDOwH .kEH40knLIFcN61pYoMav .KtRuK8LmdTWK_6fTDbW7 .bQzXNwr_0PMvf2I0HI_F {
    position: relative;
    width: 100%;
    z-index: 1
}

@media(min-width: 768px) {
    .RE36GM4qWntmSzvbDOwH .kEH40knLIFcN61pYoMav .KtRuK8LmdTWK_6fTDbW7 .bQzXNwr_0PMvf2I0HI_F {
        width:50%
    }
}
